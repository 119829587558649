<script setup>
  import Page from '@/components/Page.vue'
  import SimpleBreadCrumb from '@/components/SimpleBreadCrumb.vue'
  import { useRoute } from 'vue-router'
  import { onMounted, reactive } from 'vue'
  import php from '@/php/index.js'
  import BlogList from '@/views/blog/components/BlogList.vue'
  import Pagination from '@/components/Pagination.vue'
  import JLTools from '@/util/JLTools'
  import PageLoader from "@/views/blog/components/PageLoader.vue";
  const data = reactive({
    tag: {
      name: '',
      slug: ''
    },
    blogList: [],
    count: 0,
    total: 0,
    totalPages: 0,
    currentPage: 1,
    loading: true
  })

  const getTag = (slug) => {
    php
      .get(`services/public/tag/${slug}`)
      .then((response) => {
        data.tag.name = response.data.data.name
      })
      .catch((error) => {
        console.log('error:', error)
      })
  }

  const getBlogsByTag = (slug) => {
    data.loading = true;
    php
      .get(`services/public/blogs/tag/${slug}?page=${data.currentPage}`)
      .then((response) => {
        console.log('response:', response.data.data)
        data.blogList = response.data.data?.blogs
        data.count = response.data.data.count
        data.total = response.data.data.total
        data.currentPage = response.data.data?.currentPage
        data.totalPages = response.data.data?.totalPages
        data.loading = false;
      })
      .catch((error) => {
        console.log('error:', error)
      })
  }

  onMounted(() => {
    data.tag.slug = useRoute().params.slug || ''
    getTag(data.tag.slug)
    getBlogsByTag(data.tag.slug)
  })

  const handCurrenPage = (val) => {
    data.currentPage = val
    getBlogsByTag(data.tag.slug)
    JLTools.scrollTo(100)
  }
</script>

<template>
  <PageLoader v-if="data.loading"></PageLoader>
  <Page  v-else class="page-box">
    <simple-bread-crumb
        :firstRoute="{ name: 'Blog', path: '/blogs' }"
        :second-route="{ name: data.tag.name, path: `/blog/tags/${data.tag.slug}` }"
    ></simple-bread-crumb>
    <h1 class="py-8">{{ data.tag.name.toUpperCase() }}</h1>
    <blog-list :blogs="data.blogList"></blog-list>
    <div class="rowCC mt20 w12">
      <Pagination :config="{ total: data.totalPages, pageSize: 10, currentPage: data.currentPage }" v-if="data.totalPages > 0" @currentPage="handCurrenPage"></Pagination>
    </div>
  </Page>
</template>

<style scoped lang="less">
.py-8{
  padding:8px 0;
}
</style>
